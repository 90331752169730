<script setup>
import config from "../config";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCookies } from "vue3-cookies";
import { mdiMenu ,mdiHomeOutline, mdiCubeOutline, mdiAccountMultipleOutline, mdiHelpCircleOutline, mdiFormatListBulleted, mdiTranslate, mdiMenuUp, mdiMenuDown } from '@mdi/js'

const { locale } = useI18n();
const { cookies } = useCookies();
const active = ref(false);
const language = ref([
  {
    title: "English",
    locale: "en",
  },
  {
    title: "Русский",
    locale: "ru",
  },
]);
const setLocale = (lang) => {
  locale.value = lang;
  cookies.set("lang", lang);
  document.querySelector("html").setAttribute("lang", lang);
};

const menu = ref([
  {
    title: "home",
    path: "/",
    icon: mdiHomeOutline,
  },
  {
    title: "blocks",
    path: "/blocks",
    icon: mdiCubeOutline,
    badge: true,
  },
  {
    title: "miners",
    path: "/miners",
    icon: mdiAccountMultipleOutline,
  },
  {
    title: "help",
    path: "/help",
    icon: mdiHelpCircleOutline,
  },
]);

defineProps({
  blocks: Number,
});
</script>

<template>
  <div class="wrap pp">
    <v-app-bar :height="60" elevation="0" class="app-bar">
      <v-app-bar-nav-icon :ripple="false">
        <img :alt="config.coin" width="32" height="32" title="logo" src="../assets/coin.png" />
      </v-app-bar-nav-icon>
      <v-app-bar-title class="mx-auto title">
        {{ config.name }} <small>({{ config.coin }})</small>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn :title="item.title" v-for="(item, i) in menu" :key="`menu-${i}`" :to="item.path" :prepend-icon="item.icon"
        class="text-capitalize hidden-sm-and-down">
        {{ $t(item.title) }}
        <v-badge v-if="item.badge && blocks > 0" :content="blocks" inline color="rgba(255, 255, 255, 0.8)"></v-badge>
      </v-btn>
      <v-btn elevation="0" title="all" href="https://molepool.com" :prepend-icon="mdiFormatListBulleted"
        class="text-capitalize hidden-sm-and-down mr-2">
        {{ $t("pools") }}
      </v-btn>
      <v-divider vertical inset></v-divider>
      <v-menu v-model="active">
        <template v-slot:activator="{ props }">
          <v-btn title="lang" class="mx-2" v-bind="props">
            <v-icon :icon="mdiTranslate"></v-icon>
            <v-icon :icon="active ? mdiMenuUp : mdiMenuDown"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in language" :key="index" @click="setLocale(item.locale)">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider vertical inset class="hidden-md-and-up"></v-divider>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon text v-bind="props" class="hidden-md-and-up ml-2" :icon="mdiMenu" title="menu"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in menu" :key="i" :to="item.path">
            <v-list-item-title class="text-capitalize">
              <v-icon size="20" :icon="item.icon"></v-icon>
              {{ $t(item.title) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="//molepool.com">
            <v-list-item-title class="text-capitalize">
              <v-icon left size="20" :icon="mdiFormatListBulleted"></v-icon>
              {{ $t("pools") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>