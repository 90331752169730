import { createStore } from "vuex";
import config from "../config";
import axios from "axios";

const coin = config.coin.toLowerCase()

export default createStore({
    state: {
        status: "pending",
        error: "",
        newBlock: 0,
        home: {
            algo: "",
            scheme: "",
            reward: 0,
            fee: 0,
            totalMiners: 0,
            totalHashrate: 0,
            currentEffort: 0,
            lastBlockFound: 0,
            newBlocks: 0,
            totalBlocks: 0,
            networkHashrate: 0,
            networkDifficulty: 0,
            currentHeight: 0,
            btc: "",
            btc_24h_change: "",
            usd: "",
            usd_24h_change: "",
            chart: [],
        },
        blocks: {
            luck: {},
            candidate: [],
            blocks: [],
        },
        miners: {
            miners: [],
            totalCount: 0,
        },
        account: {
            currentHashrate: 0,
            averageHashrate: 0,
            lastBlockFound: 0,
            blocksFound: 0,
            personalShares: 0,
            personalEffort: 0,
            balance: 0,
            pending: 0,
            immature: 0,
            daysReward: 0,
            workerTotal: 0,
            workerOnline: 0,
            workerOffline: 0,
            chart: [],
            bestShare: {
                time: 0,
                share: 0,
                netDiff: 0,
                port: 0,
            },
            workers: []
        },
        account_rewards: {
            luck: {},
            sumrewards: [],
            rewards: []
        },
        account_payments: []
    },
    getters: {
        getStatus: (state) => state.status,
        getNewBlocks: (state) => state.newBlock,
        getHome: (state) => state.home,
        getBlocks: (state) => state.blocks,
        getMiners: (state) => state.miners,
        getAccount: (state) => state.account,
        getAccountRewards: (state) => state.account_rewards,
        getAccountPayments: (state) => state.account_payments,
    },
    actions: {
        async fetchNewBlock({ commit }) {
            try {
                const data = await axios.get(`/api/v1/newBlock`);
                commit("SET_NEW_BLOCKS", data.data);
            } catch (error) {
                commit("SET_ERROR", error);
            }
        },
        async fetchHome({ commit }) {
            try {
                const data = await axios.get(`/api/v1/stats`);
                commit("SET_HOME", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
        async fetchBlocks({ commit }) {
            try {
                const data = await axios.get(`/api/v1/blocks`);
                commit("SET_BLOCKS", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
        async fetchMiners({ commit }, params) {
            try {
                const data = await axios.get(`/api/v1/miners/${params.page}/${params.size}`
                );
                commit("SET_MINERS", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
        async fetchAccount({ commit }, params) {
            try {
                const data = await axios.get(`/api/v1/account/${params.login}/${params.page}/${params.size}`);
                commit("SET_ACCOUNT", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
        async fetchAccountRewards({ commit }, params) {
            try {
                const data = await axios.get(`/api/v1/account/${params.login}/rewards`);
                commit("SET_ACCOUNT_REWARDS", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
        async fetchAccountPayments({ commit }, params) {
            try {
                const data = await axios.get(`/api/v1/account/${params.login}/payments`);
                commit("SET_ACCOUNT_PAYMENTS", data.data.result);
                if (data.data.status) {
                    commit("SET_STATUS", "success");
                } else {
                    commit("SET_STATUS", "pending");
                }
            } catch (error) {
                commit("SET_STATUS", "error");
                commit("SET_ERROR", error);
            }
        },
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_NEW_BLOCKS(state, newBlock) {
            state.newBlock = newBlock;
        },
        SET_HOME(state, home) {
            state.home = home;
        },
        SET_BLOCKS(state, blocks) {
            state.blocks = blocks;
        },
        SET_MINERS(state, miners) {
            state.miners = miners;
        },
        SET_ACCOUNT(state, account) {
            state.account = account;
        },
        SET_ACCOUNT_REWARDS(state, account_rewards) {
            state.account_rewards = account_rewards;
        },
        SET_ACCOUNT_PAYMENTS(state, account_payments) {
            state.account_payments = account_payments;
        },
    },
});
