<script setup>
import config from "../config";
import { onMounted, onUnmounted, computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router'
import { useI18n } from "vue-i18n";
import { enGB, ru } from "date-fns/locale";
import { Chart } from "highcharts-vue";
import { mdiHelpCircleOutline, mdiChartLine, mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
const { proxy } = getCurrentInstance();

const store = useStore();
const route = useRoute()
const { t, locale } = useI18n();
const languages = ref({
    eu: enGB,
    ru: ru,
});

let opened = ref(1)

function toggleClass() {
    cookies.set("chart", opened.value == 0 ? true : false)
}

opened.value = cookies.get("chart") == "true" ? 0 : 1

const status = computed({
    get() {
        return store.state.status;
    },
    set(value) {
        store.commit("SET_STATUS", value);
    },
});

const account = computed({
    get() {
        return store.state.account;
    },
    set(value) {
        store.commit("SET_ACCOUNT", value);
    },
});

let page = ref(1);
let itemsPerPage = ref(15);

function next() {
    let params = {
        login: route.params.login,
        page: page.value,
        size: itemsPerPage.value,
    };
    store.dispatch("fetchAccount", params);
}

const totalRecords = computed({
    get() {
        return store.state.account.workerTotal;
    },
    set(value) {
        store.commit("SET_ACCOUNT", value);
    },
});

const pageCount = computed(() => {
    return Math.ceil(totalRecords.value / itemsPerPage.value);
});

const current = computed({
    get() {
        let push = store.state.account.chart;
        let val = [];
        push.forEach(function (d) {
            var y = d.c;
            var x = d.t;
            val.push({
                y: y,
                x: x,
            });
        });
        return val;
    },
    set(value) {
        store.commit("SET_ACCOUNT", value);
    },
});

const average = computed({
    get() {
        let push = store.state.account.chart;
        let val = [];
        push.forEach(function (d) {
            var y = d.a;
            var x = d.t;
            val.push({
                y: y,
                x: x,
            });
        });
        return val;
    },
    set(value) {
        store.commit("SET_ACCOUNT", value);
    },
});

const chartOptions = computed(() => {
    return {
        accessibility: {
            enabled: false
        },
        plotOptions: {
            series: {
                animation: {
                    duration: 0
                },
            },
            areaspline: {
                marker: {
                    enabled: false,
                },
            },
        },
        chart: {
            type: "areaspline",
            height: 280,
            ignoreHiddenSeries: false,
            backgroundColor: "rgba(0,0,0,0)",
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 1,
            borderRadius: 0,
            spacing: [4, 4, 4, 4],
            alignTicks: false,
            animation: false,
            style: {
                fontFamily: 'Nunito'
            },
        },
        title: {
            text: "",
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: "datetime",
            title: {
                enabled: false,
            },
            tickAmount: 8,
            lineWidth: 0,
            tickColor: "rgba(255, 255, 255, 0.1)",
            labels: {
                formatter: function () {
                    let time = 0;
                    if (this.value != -1 && this.value != 0) {
                        time = proxy.$filters.timestamp(this.value).toLocaleTimeString(navigator.language, {
                            hour: "numeric",
                            minute: "numeric"
                        })
                    }
                    return time
                },
                style: {
                    fontFamily: 'Nunito',
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "14",
                    fontWeight: "400"
                }
            },
        },
        yAxis: {
            title: {
                enabled: false,
            },
            tickAmount: 5,
            min: 0,
            lineWidth: 0,
            gridLineColor: "rgba(255, 255, 255, 0.1)",
            labels: {
                formatter: function () {
                    return proxy.$filters.hashrate(this.value, 0);
                },
                style: {
                    fontFamily: 'Nunito',
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "14",
                    fontWeight: "400"
                }
            },

        },
        tooltip: {
            animation: false,
            shared: true,
            shadow: false,
            headerFormat: "",
            style: {
                fontFamily: 'Nunito',
                color: "rgba(0, 0, 0, 0.8)",
                fontSize: ".7rem",
                fontWeight: "500"
            },
            crosshairs: true
        },
        legend: {
            enabled: true,
            align: "center",
            verticalAlign: "top",
            itemStyle: {
                color: "rgba(255, 255, 255, 0.8)",
                fontFamily: 'Nunito',
                fontSize: ".8rem",
                fontWeight: "500"
            },
            itemHoverStyle: {
                color: "#fafafa"
            }
        },
        series: [{
            name: t("current_hashrate"),
            data: current.value,
            color: "#00507e",
            lineWidth: 2,
            states: {
                inactive: {
                    enabled: false
                }
            },
            fillColor: false,
            tooltip: {
                pointFormatter: function () {
                    return proxy.$filters.timestamp(this.x).toLocaleTimeString(navigator.language, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                    }) + "<br> " + t("current_hashrate") + ": " + proxy.$filters.hashrate(this.y, 2);
                }
            },
        },
        {
            name: t("average_hashrate"),
            data: average.value,
            color: "#e78400",
            lineWidth: 2,
            states: {
                inactive: {
                    enabled: false,
                },
            },
            fillColor: false,
            tooltip: {
                pointFormatter: function () {
                    return "<br> " + t("average_hashrate") + ": " + proxy.$filters.hashrate(this.y, 2);
                }
            },
        }
        ],
    }
});

let intervalId;
onMounted(() => {
    let params = {
        login: route.params.login,
        page: page.value,
        size: itemsPerPage.value,
    };
    store.dispatch("fetchAccount", params);
    intervalId = setInterval(() => {
        let params = {
            login: route.params.login,
            page: page.value,
            size: itemsPerPage.value,
        };
        store.dispatch("fetchAccount", params);
    }, 10000);
});
onUnmounted(() => clearInterval(intervalId));

</script>
<template>
    <v-row dense>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("workers") }}
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <v-row no-gutters>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('online') }}
                        </div>
                        <div class="card-text text-green">
                            <span v-if="status === 'success'">
                                {{ $filters.number(account.workerOnline) }}
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="dashed"></v-divider>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('offline') }}
                        </div>
                        <div class="card-text text-red">
                            <span v-if="status === 'success'">
                                {{ $filters.number(account.workerOffline) }}
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("hashrate") }}
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <v-row no-gutters>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t("current_hashrate") }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                <span v-html="$filters.hashrate(account.currentHashrate, 2)"></span>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="dashed"></v-divider>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t("average_hashrate") }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                <span v-html="$filters.hashrate(account.averageHashrate, 2)"></span>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("work") }}
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <v-row no-gutters>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('shares_sum') }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                <span v-html="$filters.difficulty(account.personalShares, 2)"></span>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="dashed"></v-divider>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('personal_effort') }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                <span v-if="account.personalEffort >= 1.0" class="text-blue">
                                    {{ $filters.effort(account.personalEffort, 3) }}
                                </span>
                                <span v-else class="text-green">
                                    {{ $filters.effort(account.personalEffort, 3) }}
                                </span>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="dashed"></v-divider>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('blocks') }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                {{ account.blocksFound }}
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("reward") }}
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <v-row no-gutters>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('unconfirmed') }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                {{ $filters.reward(account.immature, 6) }}
                                <small class="text-muted">
                                    {{ config.symbol }}
                                </small>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="dashed"></v-divider>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('unpaid_balance') }}
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                {{ $filters.reward(account.balance, 6) }}
                                <small class="text-muted">
                                    {{ config.symbol }}
                                </small>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                    <v-divider vertical class="dashed"></v-divider>
                    <v-col>
                        <div class="card-subtitle">
                            {{ $t('pending_confirmation') }}
                            <v-tooltip location="top" max-width="280">
                                <template v-slot:activator="{ props }">
                                    <v-icon size="17" v-bind="props" :icon="mdiHelpCircleOutline"> </v-icon>
                                </template>
                                <span>
                                    {{ $t('pending_confirmation_info') }}
                                </span>
                            </v-tooltip>
                        </div>
                        <div class="card-text">
                            <span v-if="status === 'success'">
                                {{ $filters.reward(account.pending, 6) }}
                                <small class="text-muted">
                                    {{ config.symbol }}
                                </small>
                            </span>
                            <span v-else>
                                <v-skeleton-loader pill height="24" />
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" class="">
            <v-expansion-panels id="chart" v-model="opened" class="border rounded">
                <v-expansion-panel :elevation="0" @click="toggleClass" class="rounded" bg-color="#292929">
                    <v-expansion-panel-title :collapse-icon="mdiChevronDown" :expand-icon="mdiChevronUp">
                        <v-icon size="20" start :icon="mdiChartLine"></v-icon>
                        {{ $t('hashrate_chart') }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <span v-if="status === 'success'">
                            <Chart :key="locale" :options="chartOptions"></Chart>
                        </span>
                        <span v-else>
                            <v-skeleton-loader class="rounded-t-0" pill height="280" />
                        </span>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
    <div v-if="status === 'success'" class="my-1">
        <v-chip-group class="">
            <v-chip label>
                {{ $t('last_best_share') }}:
                <timeago v-if="account.bestShare.time > 0" class="ml-1" :key="locale" :locale="languages[locale]"
                    :datetime="account.bestShare.time * 1000" />
                <span v-else class="ml-1">0</span>
            </v-chip>
            <v-chip label>
                {{ $t('best_share') }}:
                <span v-if="account.bestShare.time > 0" class="ml-1"
                    v-html="$filters.difficulty(account.bestShare.share, 3)"></span>
                <span v-else class="ml-1">0</span>
            </v-chip>
            <v-chip label>
                {{ $t("network_difficulty") }}:
                <span v-if="account.bestShare.time > 0" class="ml-1"
                    v-html="$filters.difficulty(account.bestShare.netDiff, 3)"></span>
                <span v-else class="ml-1">0</span>
            </v-chip>
            <v-chip label>
                {{ $t("port_difficulty") }}:
                {{ account.bestShare.port }}
            </v-chip>
        </v-chip-group>
    </div>
    <div v-else class="my-3">
        <v-skeleton-loader pill height="32" />
    </div>
    <v-table density="comfortable" class="border rounded">
        <thead>
            <tr>
                <th>{{ $t('worker') }}</th>
                <th>{{ $t('current') }}</th>
                <th>{{ $t('average') }}</th>
                <th>
                    {{ $t('accept') }}
                    <v-tooltip location="top" max-width="280">
                        <template v-slot:activator="{ props }">
                            <v-icon size="17" v-bind="props" :icon="mdiHelpCircleOutline"></v-icon>
                        </template>
                        <span>
                            {{ $t('shares_stats') }}
                        </span>
                    </v-tooltip>
                </th>
                <th>{{ $t('invalid') }}</th>
                <th>{{ $t('stale') }}</th>
                <th>{{ $t('best_share') }}</th>
                <th>{{ $t('port') }}</th>
                <th>{{ $t('last_shares') }}</th>
            </tr>
        </thead>
        <tbody v-if="account == undefined ? 0 : account.workerTotal > 0">
            <tr v-for="(item, index) in account.workers" :key="index">
                <td>
                    <v-code tag="span" :class="item.offline ? 'text-red' : ''">
                        {{ item.name }}
                    </v-code>
                </td>
                <td>
                    <span v-html="$filters.hashrate(item.currentHashrate, 2)"></span>
                </td>
                <td>
                    <span v-html="$filters.hashrate(item.averageHashrate, 2)"></span>
                </td>
                <td>
                    {{ item.accept }}
                    <!--span v-html="$filters.shareAccept(item.accept, item.stale, item.invalid)"></span-->
                </td>
                <td>
                    <span v-html="$filters.shareAccept(item.invalid, item.accept, item.stale)"></span>
                </td>
                <td>
                    <span v-html="$filters.shareAccept(item.stale, item.accept, item.invalid)"></span>
                </td>
                <td>
                    <span v-html="$filters.difficulty(item.bestShare, 3)"></span>
                </td>
                <td>
                    <v-code tag="span">
                        <span v-html="$filters.difficulty(item.diff, 0)"></span>
                        {{ item.server.toUpperCase() }}
                    </v-code>
                </td>
                <td>
                    <span v-if="item.lastShares != 0">
                        <timeago :key="locale" :locale="languages[locale]" :datetime="item.lastShares * 1000" />
                    </span>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="9" class="text-center">
                    {{ $t('no_data') }}
                </td>
            </tr>
        </tbody>
    </v-table>
    <v-pagination v-if="totalRecords > itemsPerPage" class="mt-2" density="comfortable" v-model="page" :length="pageCount"
        :total-visible="7" @click="next()"></v-pagination>
</template>
<style>
.off {
    background-color: rgba(255, 81, 1, 0.05)
}

.on {
    background-color: rgba(71, 114, 67, 0.1)
}
</style>