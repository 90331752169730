<script setup>
import config from "../config";
import { ref } from "vue";
const service = ref([
  {
    text: 'Mining Calculator',
    url: 'https://whattomine.com',
  },
  {
    text: 'NiceHash Rentals',
    url: 'https://www.nicehash.com/?refby=453cd539-1f58-49cf-8715-ed3ecf6b4c53',
  },
  {
    text: 'Mining Rig Rentals',
    url: 'https://www.miningrigrentals.com/register?ref=29888',
  },
])

const agreements = ref([
  {
    text: 'Privacy Statement',
    url: '/terms/privacy_statement.pdf'
  },
  {
    text: 'Terms and Conditions',
    url: '/terms/terms_and_conditions.pdf'
  }
])

const feedback = ref([
  {
    text: 'Telegram',
    url: 'https://t.me/molepoolnet',
  },
  {
    text: 'Telegram News',
    url: 'https://t.me/Molepool_news',
  },
  {
    text: 'Email',
    url: 'mailto:info.molepool@gmail.com',
  },
])

</script >
<template>
  <div class="footer">
    <v-divider></v-divider>
    <v-footer color="#272727" elevation="0" class="">
      <v-container class="wrap">
        <v-row>
          <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
            <div class="text-subtitle-1">
              {{ $t('information') }}
            </div>
            <ul>
              <li>
                <a :href="config.explorer_url" target="_blank" rel="noopener noreferrer">
                  {{ $t("block_explorer") }}
                </a>
              </li>
              <li>
                <a :href="config.explorer_url" target="_blank" rel="noopener noreferrer">
                  {{ $t('network_stats') }}
                </a>
              </li>
              <li>
                <a :href="config.explorer.address + config.account" target="_blank" rel="noopener noreferrer">
                  {{ $t('pool_wallet') }}
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
            <div class="text-subtitle-1">
              {{ $t('services') }}
            </div>
            <ul>
              <li v-for="(item, i) in service" :key="i">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
            <div class="text-subtitle-1">
              {{ $t('agreements') }}
            </div>
            <ul>
              <li v-for="(item, i) in agreements" :key="i">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="2" lg="2" xl="2">
            <div class="text-subtitle-1">
              {{ $t('feedback') }}
            </div>
            <ul>
              <li v-for="(item, i) in feedback" :key="i">
                <a :href="item.url" target="_blank" rel="noopener noreferrer">
                  {{ item.text }}
                </a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <div class="text-subtitle-1">
              {{ $t('compatible') }}
            </div>
            <ul>
              <li>
                <a aria-label="miningrigrentals" href="https://www.miningrigrentals.com?ref=29888" target="_blank"
                  rel="noopener noreferrer">
                  <img alt="mrr" class="mrr" width="120" height="26" src="../assets/mrr.png">
                </a>
              </li>
              <li>
                <a aria-label="nicehash" href="https://www.nicehash.com/?refby=453cd539-1f58-49cf-8715-ed3ecf6b4c53"
                  target="_blank" rel="noopener noreferrer">
                  <img alt="nice" class="mrr" width="120" height="20" src="../assets/nice.png">
                </a>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-divider></v-divider>
    <v-footer app elevation="0" class="app-footer">
      <v-row justify="center" no-gutters>
        <v-col class="text-center sub py-2" cols="12">
          {{ config.name }} ({{ config.coin }}) &copy; molepool.com 2017 - {{
            new Date().getFullYear() }}
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>