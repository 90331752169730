import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Home from '../page/index.vue'
import Blocks from '../page/blocks.vue'
import Miners from '../page/miners.vue'
import Help from '../page/help.vue'
import Account from '../page/account.vue'
import NotFound from '../page/notFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/blocks',
    name: 'blocks',
    component: Blocks
  },
  {
    path: '/miners',
    name: 'miners',
    component: Miners,
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/account/:login',
    name: 'account',
    component: Account,
  },
  {
    path: '/account/:login/:page',
    component: Account,
    children: [
      {
        path: '',
      },
      {
        path: 'rewards',
      },
      {
        path: 'payouts',
      },
    ],
  },
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/404',
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(),
})

router.beforeEach(async (to, from, next) => {
  await store.restored;
 // if (to.params.login !== from.params.login)
   // if (to.params.login !== undefined)
   //   await store.dispatch("fetchAccount", to.params);
  next();
})

export default router