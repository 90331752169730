import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ru from './locales/ru.json'

export const defaultLocale = 'en'

export const languages = {
    en: en,
    ru: ru,
}

const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages: languages
});

export default i18n;