<script setup>
import config from "../config";
import { onMounted, onUnmounted, computed } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from "vuex";

const store = useStore();
const route = useRoute()

const status = computed({
    get() {
        return store.state.status;
    },
    set(value) {
        store.commit("SET_STATUS", value);
    },
});

const payments = computed({
    get() {
        return store.state.account_payments;
    },
    set(value) {
        store.commit("SET_ACCOUNT_PAYMENTS", value);
    },
});

let intervalId;
onMounted(() => {
    let params = {
        login: route.params.login,
    };
    store.dispatch("fetchAccountPayments", params);
    intervalId = setInterval(() => {
        let params = {
            login: route.params.login,
        };
        store.dispatch("fetchAccountPayments", params);
    }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>
<template>
    <v-table density="comfortable" class="border rounded">
        <thead>
            <tr>
                <th>{{ $t('time') }}</th>
                <th>
                    {{ $t('amount') }}
                    <small class="text-muted">
                        {{ config.coin }}
                    </small>
                </th>
                <th>TX Fee</th>
                <th>TX</th>
            </tr>
        </thead>
        <tbody v-if="payments == undefined ? 0 : payments.length > 0">
            <tr v-for="(item, index) in payments" :key="index">
                <td>{{ $filters.time(item.timestamp) }}</td>
                <td>
                    {{ $filters.reward(item.amount, 6) }}
                </td>
                <td>
                    {{ $filters.reward(item.txFee, 9) }}
                </td>
                <td class="text-monospace">
                    <a :href="config.explorer.tx + item.tx" target="_blank" rel="noopener noreferrer" class="text-monospace">
                        {{ item.tx }}
                    </a>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="3" class="text-center">
                    {{ $t('no_data') }}
                </td>
            </tr>
        </tbody>
    </v-table>
</template>