export default {
    install: (app) => {
        app.config.globalProperties.$filters = {
            hashrate(value, fix = 2) {
                let sum = parseFloat(value);
                if (sum == undefined) {
                    sum = 0;
                }
                var i = 0;
                let units = ["H/s", "KH/s", "MH/s", "GH/s", "TH/s", "PH/s", "EH/s", "ZH/s", "YH/s"];
                while (sum > 1000) {
                    sum = sum / 1000;
                    i++;
                }
                return sum.toFixed(fix) + " <spam class='text-muted'>" + units[i] + "</spam>";
            },
            timestamp(value, toLocaleString = false) {
                value = new Date(1000 * value);
                if (toLocaleString) {
                    return value.toLocaleString();
                }
                return value;
            },
            time(value, toLocaleString = false) {
                value = new Date(1000 * value);
                if (toLocaleString) {
                    return value.toLocaleString();
                }
                return value.toLocaleTimeString(navigator.language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                });
            },
            timeDay(value) {
                var options = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                }
                return new Intl.DateTimeFormat(navigator.language, options).format(value * 1000);
            },
            difficulty(value, fix = 2) {
                let hash = parseFloat(value);
                if (hash == undefined) {
                    hash = 0;
                }
                var i = 0;
                let units = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"];
                while (hash > 1000) {
                    hash = hash / 1000;
                    i++;
                }
                return hash.toFixed(Math.ceil(fix)) + "<spam class='text-muted'>" + units[i] + "</spam>";
            },
            price_percent(value, digits = 2) {
                let percent = value / 100;
                return Number(percent).toLocaleString("en", {
                    style: "percent",
                    minimumFractionDigits: digits,
                });
            },
            effort(value, fix) {
                return Number(value).toLocaleString("en", {
                    style: "percent",
                    minimumFractionDigits: fix,
                });
            },
            round(value, fix) {
                if (value >= 1.0) {
                    value = 1;
                    fix = 0;
                }
                return Number(value).toLocaleString("en", {
                    style: "percent",
                    minimumFractionDigits: fix,
                });
            },
            tx(value, start, end) {
                return value.substring(0, start) + "..." + value.substring(end);
            },
            number(value) {
                value = parseInt(value);
                value = new Intl.NumberFormat(undefined).format(value);
                return value;
            },
            shareAccept(accept, stale, invalid) {
                var share = accept + stale + invalid;
                var result = accept / share;
                if (result >= 1) {
                    result = (1 * 100).toFixed(0);
                } else {
                    result = (result * 100).toFixed(0);
                }
                if (result == "NaN") {
                    result = 0;
                }
                return accept + "<spam class='text-muted'>(" + result + "%)</spam>";
            },
            shareStale(stale, accept, invalid) {
                var share = accept + stale + invalid;
                var result = stale / share;
                if (result >= 1) {
                    result = (1 * 100).toFixed(0);
                } else {
                    result = (result * 100).toFixed(0);
                }
                if (result == "NaN") {
                    result = 0;
                }
                return stale + "<spam class='text-muted'>(" + result + "%)</spam>";
            },
            shareInvalid(invalid, accept, stale) {
                var share = accept + stale + invalid;
                var result = invalid / share;
                if (result >= 1) {
                    result = (1 * 100).toFixed(0);
                } else {
                    result = (result * 100).toFixed(0);
                }
                if (result == "NaN") {
                    result = 0;
                }
                return invalid + "<spam class='text-muted'>(" + result + "%)</spam>";
            },
            price(value, digits = 2) {
                return Number(value).toLocaleString("en", {
                    style: "currency",
                    minimumFractionDigits: digits,
                    currency: "USD",
                });
            },
            reward(value, digits = 2) {
                value = parseFloat(value)
                return value.toFixed(digits).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
        }
    }
}
