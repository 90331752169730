<script setup>
import config from "../config";
import AccountDashboard from './account_dashboard.vue'
import AccountRewards from './account_rewards.vue'
import AccountPayments from './account_payments.vue'
import { ref } from "vue";
import { useRoute } from 'vue-router'
import { mdiOpenInNew, mdiViewDashboard, mdiCashPlus, mdiSendVariantOutline } from '@mdi/js'

const route = useRoute()

let tab = ref('dashboard')
let params = route.params

let tabName = ref({
    dashboard: mdiViewDashboard,
    rewards: mdiCashPlus,
    payouts: mdiSendVariantOutline,
});

</script>
<template>
    <div class="text-center">
        <v-btn class="text-monospace mb-4 none-transform" variant="text"
            :href="config.explorer.address + route.params.login" target="_blank" rel="noopener noreferrer">
            {{ $filters.tx(route.params.login, 12, route.params.login.length - 12) }}
            <template v-slot:append>
                <v-icon color="warning" :icon="mdiOpenInNew"></v-icon>
            </template>
        </v-btn>
    </div>
    <v-menu class="rounded">
        <template v-slot:activator="{ props }">
            <v-btn block flat variant="tonal" size="large" :prepend-icon="tabName[tab]" v-bind="props"
                class="hidden-md-and-up mb-2 new">
                {{ $t(tab) }}
            </v-btn>
        </template>
        <v-list v-model="tab">
            <v-list-item href="#dashboard" :to="`/account/${params.login}`">
                <v-list-item-title class="text-capitalize">
                    <v-icon size="20" :icon="mdiViewDashboard"></v-icon>
                    {{ $t('dashboard') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item href="#rewards" :to="`/account/${params.login}/rewards`">
                <v-list-item-title class="text-capitalize">
                    <v-icon size="20" :icon="mdiCashPlus"></v-icon>
                    {{ $t('rewards') }}
                </v-list-item-title>
            </v-list-item>
            <v-list-item href="#payouts" :to="`/account/${params.login}/payouts`">
                <v-list-item-title class="text-capitalize">
                    <v-icon size="20" :icon="mdiSendVariantOutline"></v-icon>
                    {{ $t('payouts') }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-tabs v-model="tab" show-arrows="" :mobile-breakpoint="0" grow class="hidden-sm-and-down mb-3 border rounded">
        <v-tab :prepend-icon="mdiViewDashboard" value="dashboard" :to="`/account/${params.login}`">
            {{ $t('dashboard') }}
        </v-tab>
        <v-tab :prepend-icon="mdiCashPlus" value="rewards" :to="`/account/${params.login}/rewards`">
            {{ $t('rewards') }}
        </v-tab>
        <v-tab :prepend-icon="mdiSendVariantOutline" value="payouts" :to="`/account/${params.login}/payouts`">
            {{ $t('payouts') }}
        </v-tab>
    </v-tabs>

    <div v-if="route.params.page == null" class="pt-1">
        <AccountDashboard />
    </div>
    <div v-if="route.params.page === 'rewards'" class="pt-1">
        <AccountRewards />
    </div>
    <div v-if="route.params.page === 'payouts'" class="pt-1">
        <AccountPayments />
    </div>
</template>