export default {
    name: "OctaSpace",
    coin: "OCTA",
    scheme: "SOLO",
    account: '0x52A696d5ef62B5c9aC6b0C82d91520029ffc25E1',
    explorer_url: 'https://scan.octa.space',
    coingecko: 'https://www.coingecko.com/en/coins/octaspace',
    explorer: {
        address: "https://scan.octa.space/address/",
        height: "https://scan.octa.space/block/",
        hash: "https://scan.octa.space/block/",
        tx: "https://scan.octa.space/tx/",
    },
    ports: [
        {
            info: "LOW",
            host: "molepool.com",
            port: 2801,
            diff: 2,
        },
        {
            info: "MID",
            host: "molepool.com",
            port: 2802,
            diff: 8,
        },
        {
            info: "HIGH",
            host: "molepool.com",
            port: 2803,
            diff: 16,
        }
    ]
};