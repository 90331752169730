import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import filters from './filters'
import store from './store'
import timeago from 'vue-timeago3'
import { useCookies } from "vue3-cookies"
import Clipboard from "v-clipboard";

const { cookies } = useCookies();
i18n.global.locale.value = cookies.get('lang') || 'en';
vuetify.locale.current = cookies.get('lang') || 'en';
document.querySelector("html").setAttribute("lang", cookies.get('lang') || 'en');

const timeagoOptions = {
    converterOptions: {
        includeSeconds: true,
        addSuffix: true,
        useStrict: true,
    }
}

const app = createApp(App)
app.use(Clipboard);
app.use(router)
app.use(i18n)
app.use(vuetify)
app.use(timeago, timeagoOptions)
app.use(filters)
app.use(store)
app.mount('#app')
